/* eslint-disable no-undef */
import React from "react"
import StructurePageKontigo from "@components/pageCasosdeExito/template/templatePageKontigo"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Kontigo [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/kontigo/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Conoce como Kontigo, ha centralizado su atención al cliente digital con Beex, plataforma omnicanal." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Kontigo" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/kontigo/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-kontigo.jpg`}
      />
      <meta property="og:description" content="Conoce como Kontigo, ha centralizado su atención al cliente digital con Beex, plataforma omnicanal." />
    </Helmet>
    <StructurePageKontigo location={location} />
  </div>
)

export default IndexPage
